export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_ADMIN_LIST_REQUEST = "ORDER_ADMIN_LIST_REQUEST";
export const ORDER_ADMIN_LIST_SUCCESS = "ORDER_ADMIN_LIST_SUCCESS";
export const ORDER_ADMIN_LIST_FAIL = "ORDER_ADMIN_LIST_FAIL";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

export const ORDER_SEND_REQUEST = "ORDER_SEND_REQUEST";
export const ORDER_SEND_SUCCESS = "ORDER_SEND_SUCCESS";
export const ORDER_SEND_FAIL = "ORDER_SEND_FAIL";
export const ORDER_SEND_RESET = "ORDER_SEND_RESET";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";
export const ORDER_CANCEL_RESET = "ORDER_CANCEL_RESET";
