export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_FORGOT_PASS_REQUEST = "USER_FORGOT_PASS_REQUEST";
export const USER_FORGOT_PASS_SUCCESS = "USER_FORGOT_PASS_SUCCESS";
export const USER_FORGOT_PASS_FAIL = "USER_FORGOT_PASS_FAIL";

export const USER_RESET_PASS_REQUEST = "USER_RESET_PASS_REQUEST";
export const USER_RESET_PASS_SUCCESS = "USER_RESET_PASS_SUCCESS";
export const USER_RESET_PASS_FAIL = "USER_RESET_PASS_FAIL";
