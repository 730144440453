export const GALLERY_IMAGE_LIST_REQUEST = "GALLERY_IMAGE_LIST_REQUEST";
export const GALLERY_IMAGE_LIST_SUCCESS = "GALLERY_IMAGE_LIST_SUCCESS";
export const GALLERY_IMAGE_LIST_FAIL = "GALLERY_IMAGE_LIST_FAIL";

export const GALLERY_IMAGE_CREATE_REQUEST = "GALLERY_IMAGE_CREATE_REQUEST";
export const GALLERY_IMAGE_CREATE_SUCCESS = "GALLERY_IMAGE_CREATE_SUCCESS";
export const GALLERY_IMAGE_CREATE_FAIL = "GALLERY_IMAGE_CREATE_FAIL";
export const GALLERY_IMAGE_CREATE_RESET = "GALLERY_IMAGE_CREATE_RESET";

export const GALLERY_IMAGE_DELETE_REQUEST = "GALLERY_IMAGE_DELETE_REQUEST";
export const GALLERY_IMAGE_DELETE_SUCCESS = "GALLERY_IMAGE_DELETE_SUCCESS";
export const GALLERY_IMAGE_DELETE_FAIL = "GALLERY_IMAGE_DELETE_FAIL";
export const GALLERY_IMAGE_DELETE_RESET = "GALLERY_IMAGE_DELETE_RESET";
